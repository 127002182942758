import styles from '../../routes/Hub.module.scss'; // TODO: use own module.scss

import { FontIcon } from '@fluentui/react';
import React, { ReactNode, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MemberLogo } from '../atoms/MemberLogo';

export interface Props {
	logoSrc?: string;
	title?: string;
	visible: boolean;
	renderMenu?: () => ReactNode;
	onClose: () => void;
}

export const SidePanel = (props: Props) => {
	const menuRef = useRef(null);

	useEffect(() => {
		toggleBodyStyle(props.visible);
	}, [props.visible]);

	const toggleBodyStyle = (visible: boolean) => {
		if (visible) {
			window.scrollTo(0, 0);
			document.body.style['overflow'] = 'hidden';
		} else {
			document.body.style['overflow'] = '';
		}
	};

	return (
		<CSSTransition nodeRef={menuRef} in={props.visible} timeout={2500} classNames='sidePanelOpen'>
			<div ref={menuRef} className={[styles.menuPage, props.visible ? styles.open : ''].join(' ')}>
				<div className={styles.headerContainer}>
					{props.logoSrc && <MemberLogo src={props.logoSrc} />}
					<div className={styles.headerInnerContainer}>
						{!props.logoSrc && <h1>Menü</h1>}
						<div
							className={styles.menuButton}
							onClick={() => {
								toggleBodyStyle(false);
								props.onClose();
							}}
						>
							<FontIcon iconName='Cancel' />
						</div>
					</div>
				</div>
				{props.renderMenu && props.renderMenu()}
			</div>
		</CSSTransition>
	);
};
