import { IQuestion, IPersonAnsweredOption, IAnsweredOption } from '../../store/business/interfaces';
import { QuestionService } from '../QuestionService';
import { IManager } from './IManager';

class PersonManager implements IManager {

	areYouResponsible(question: IQuestion) {
		return question.kind === "person";
	};

	prepareAnsweredOptions(answeredOptions: IAnsweredOption[]) {
		const result : IPersonAnsweredOption[] = (answeredOptions as IPersonAnsweredOption[])?.map((o) => {
			return {
				id: o.id,
				voteWeight: o.voteWeight,
				answerChoice: o.answerChoice,
				ballotNumber: o.ballotNumber
			};
		}) ?? [];
		return result;
	}
}

QuestionService.addManager(new PersonManager());