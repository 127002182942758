import React from "react";
import styles from "./Badge.module.scss";
import {Icon} from "@fluentui/react";

export interface Props {
  icon: string;
  information: string;
}

export const Badge = (props: Props) => {
  return (
    <div className={styles.container}>
      {props.icon ? <Icon iconName={props.icon}></Icon> : <></>}
      <div>
        {props.information.includes("undefined")
          ? "Keine Informationen"
          : props.information}
      </div>
    </div>
  );
};
