// this hook enables us to use callbacks in component props in useEffect hooks within that component without the component indefinitely rerendering to
// also avoid the linter warning "React Hook useEffect has a missing dependency: 'props'..." (react-hooks/exhaustive-deps)
// see https://hmos.dev/en/avoid-re-render-by-function-props

import { useRef, useEffect, useMemo } from 'react';

export function useCallbackRef(callback: any) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(
    () =>
      (...args: any) =>
        callbackRef.current?.(...args),
    [],
  );
}