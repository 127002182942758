import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IAnswerOptionResult, IQuestionResult, IAnswerOption } from "../../store/business/interfaces";
import store, { AppState } from "../../store/store";
import { PieChartLegend } from "./PieChartLegend";

import styles from "./ResultPieChart.module.scss";
import { setPieHeight } from "../../store/system/system-slice";

export interface IResultPieChartProps {
	question: IQuestionResult;
}

interface IPieData {
    name: string;
    value: number;
    answerOption: IAnswerOption;
}

export const ResultPieChart = (props: IResultPieChartProps) => {
    const { t } = useTranslation();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);
    const survey = useSelector((state: AppState) => state.business.survey);
    const [pieData, setPieData] = useState<IPieData[]>([]);
    const PIE_COLORS = ['#F2C811', '#0078D4', '#107C41', '#862EB5', '#C43E1C'];

    useEffect(() => {
		function handleResize() {
			setIsMobileScreen(window.innerWidth < 501);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

    useEffect(() => {
		function handleResize() {
			setIsSmallScreen(window.innerWidth < 771);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

    // Create a new option for no votes
	useEffect(() => {
		if (props.question) {
            let pieDataRaw = props.question.answerOptions.map((option) => {
                return { name: option.title, value: option.votesCount, answerOption: option };
            });
            if(survey.sortResultsByVotes) {
                pieDataRaw = pieDataRaw.sort((a, b) => b.value - a.value);
            }
            if (survey.useVoteWeightInPercent && props.question.answerOptions.length > 0) {
                const notVotedOption = {
					title: t('results.notVoted'),
					votesCount: 100 - props.question.answerOptions.reduce((sum, currentOption) => sum + currentOption.votesCount, 0),
				} as IAnswerOptionResult;
        		pieDataRaw.push({
                    name: notVotedOption.title,
                    value: notVotedOption.votesCount,
                    answerOption: notVotedOption
                });
            }
            setPieData(pieDataRaw);
		}
	}, [props.question, survey, t]);
    
        const ref = useRef(null)

        useEffect(() => {
            store.dispatch(setPieHeight(ref.current.lastBoundingBox.height))
        })

        
    

    return (
        <>
        <div className={styles.responsiveContainer}>
            <PieChart key='pie' width={780} height={300}>
                <Pie key='pie' data={pieData} outerRadius={isSmallScreen ? 80 : 160} cx={isSmallScreen? "20%" : ""} dataKey='value' label>
                    {pieData.map((entry, index) => (
                        <Cell key={`pie-cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
            
            <PieChart id="scrollableLegend" className={styles.scrollableLegend}  key='legend' width={780} height={300}>
                <Pie key='legend' data={pieData} outerRadius={0} dataKey='value'>
                    {pieData.map((entry, index) => (
                        <Cell key={`legend-cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                    ))}
                </Pie>
                <Legend ref={ref}
                    width={isMobileScreen ? 300 : 400}
                    layout='vertical'
                    verticalAlign={'top'}
                    align={isSmallScreen ? 'left' : 'right'}
                    content={PieChartLegend}
                />
            </PieChart>
            </div>
        </>
    );
}