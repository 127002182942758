import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: require('./en.json'),
	de: require('./de.json'),
};

i18n
	.use(LanguageDetector) // detect user language
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		detection: {
			checkWhitelist: true,
			order: ['querystring', 'navigator', 'path', 'subdomain'],
		},

		whitelist: ['de', 'en'],
		fallbackLng: {
			// 'de-DE': ['de'],
			// 'en-US': ['en'],
			default: ['en'],
		},
		resources,
		keySeparator: '.',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		debug: process.env.NODE_ENV !== 'production',
	})
	.then(() => {
		document.documentElement.lang = i18n.language;
	});

export default i18n;
