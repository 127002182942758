import React, { useEffect, useRef } from 'react';
import styles from './App.module.scss';
import { BrowserRouter } from 'react-router-dom';
import { Hub } from './routes/Hub';
import { AppState, store } from './store/store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useDoubleTap } from './hooks/useDoubleTap';
import { setApiBasePath, addMessage, clearMessages, removeMessage } from './store/system/system-slice';
import './services/manager/PersonManager';
import './services/manager/RatingManager';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { MessageType, startNotification } from './shared/notifications';
import { Messages } from './shared/components/atoms/Messages';

//start notifications
startNotification({
	add: (type: MessageType, message: string, sticky: boolean) => {
		store.dispatch(addMessage({created: new Date(),type, message, sticky}));
	},
	clear: () => {
		store.dispatch(clearMessages());
	},
});

export interface IAppProps {
	config: any;
}

const InnerApp = (props: IAppProps) => {
	const refRoot = useRef<HTMLDivElement>();
	const doubleTap = useDoubleTap();

	useEffect(() => {
		refRoot?.current?.addEventListener('touchstart', doubleTap, { passive: false });
	});

	const calcRealHeight = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};
	window.addEventListener('resize', () => {
		calcRealHeight();
	});
	calcRealHeight();

	store.dispatch(setApiBasePath(`${props.config.apiUrl}/api/v1`));

	return (
		<div className={styles.app} ref={refRoot}>
			<BrowserRouter>
				<Hub />
			</BrowserRouter>
		</div>
	);
};

const ReduxedApp = (props: IAppProps) => {
	const dispatch = useDispatch();
	const messages = useSelector((state: AppState) => state.system.messages);

	const appClientId = useSelector((state: AppState) => state.session.appConfig?.clientId);
	const tenantGuid = useSelector((state: AppState) => state.session.appConfig?.tenantId);

	const msalConfig = {
		auth: {
		  clientId: appClientId,
		  authority: `https://login.microsoftonline.com/${tenantGuid}`,
		  redirectUri: window.location.protocol + '//' + window.location.host + '/ms-login'
		},
		cache: {
		  cacheLocation: 'sessionStorage',
		  storeAuthStateInCookie: false,
		},
	};

	const msalInstance = new PublicClientApplication(msalConfig);

	return(
		<MsalProvider instance={msalInstance}>
			<InnerApp {...props} />
			<Messages messages={messages} onRemoveMessage={(i) => dispatch(removeMessage(i))} />
		</MsalProvider>
	)
}

export const App = (props: IAppProps) => {
	return (
		<Provider store={store}>
			<ReduxedApp {...props} />
		</Provider>
	);
};
