import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDesign, IQuestion, IQuestionResult, ISurvey } from "./interfaces";

export interface IBusinessState {
    survey?: ISurvey;
    questions?: IQuestion[];
    questionResult?: IQuestionResult;
    design?: IDesign;
};

const businessSlice = createSlice({
    name: "business",
    initialState: {} as IBusinessState,
    reducers: {
        surveyLoaded: (state: IBusinessState, action: PayloadAction<ISurvey>) => {
            state.survey = action.payload;
        },
        questionLoaded: (state: IBusinessState, action: PayloadAction<IQuestion[]>) => {
            state.questions = action.payload;
        },
        questionResultLoaded: (state: IBusinessState, action: PayloadAction<IQuestionResult>) => {
            state.questionResult = action.payload;
        },
        designLoaded: (state: IBusinessState, action: PayloadAction<IDesign>) => {
            state.design = action.payload;
        },
    },
})

export const selectBusiness = (state: any) => state.business;
export const businessReducer = businessSlice.reducer;
export const { surveyLoaded, questionLoaded, questionResultLoaded, designLoaded } = businessSlice.actions;
