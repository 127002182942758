import round from 'lodash/round';

function formatDateTime(date?: Date) {
	return formatDate(date) + ' ' + formatTime(date);
}

function formatDate(date?: Date) {
	if (!date || isNaN(date.getTime())) return '';
	if (typeof date === 'string') date = new Date(date);
	return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
}

function formatTime(date?: Date) {
	if (!date || isNaN(date.getTime())) return '';
	if (typeof date === 'string') date = new Date(date);
	return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).substr(0, 5);
}

function formatFloat(value?: Number, precision: number = null, localeString = 'de-DE') {
	const v = value ?? 0;
	if(precision != null) {
		return v.toLocaleString(localeString, { minimumFractionDigits: precision, maximumFractionDigits: 10 });
	}
	else {
		return v.toLocaleString(localeString, { maximumFractionDigits: 10 });
	}
}

function isEmptyGuid(guid?: string) {
	if (!guid) return true;
	if (guid === '00000000-0000-0000-0000-000000000000') return true;
	return false;
}
function anyInArray(value: any[] | undefined) {
	if (!value) return false;
	return value.length > 0;
}

function padNumber(input: number, length: number = 2, padString = '0'): string {
	let result = `${input}`;
	while (result.length < length) {
		result = padString + result;
	}
	return result;
}

async function asyncForEach<T>(array: T[], callback: (value: T, index: number, array: T[]) => Promise<any>) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}

function createGuid() {
	function S4() {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	}
	return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
}

function isGuid(guid: string) {
	return guid?.match(/^[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}$/i) ?? false;
}

function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function dataUrlToFile(dataUrl: string, filename: string): File {
	var arr = dataUrl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}

function getNextFocusableElement(currentElement: Element, tagNames?: string[]): HTMLElement | undefined {
	if (!tagNames) {
		tagNames = ['button', 'input', 'select', 'textarea'];
	}
	const nextElement = currentElement.nextElementSibling;
	if (!nextElement) {
		const parentElement = currentElement.parentElement;
		if (parentElement) {
			return getNextFocusableElement(parentElement, tagNames);
		}
		//nothing found
		return;
	}
	const nextTabIndex = nextElement.getAttribute('tabindex');
	if (nextTabIndex === '-1') {
		return getNextFocusableElement(nextElement, tagNames);
	}
	if (tagNames.findIndex((n) => n.toUpperCase() === nextElement.tagName) > -1) {
		return nextElement as HTMLElement;
	} else {
		const childElements = nextElement.querySelectorAll(tagNames.join(','));
		for (let i = 0; i < childElements.length; i++) {
			if (childElements.item(i).getAttribute('tabindex') !== '-1') {
				return childElements.item(i) as HTMLElement;
			}
		}
		return getNextFocusableElement(nextElement, tagNames);
	}
}

function parseFloatPrecise(number: number | string, precision = 5) {
	let floatNumber:number = 0;
	if(typeof(number) === 'string') {
		floatNumber = parseFloat(number);
	}
	else{
		floatNumber = number;
	}

	return round(floatNumber, precision);
}

export {
	formatDateTime,
	formatDate,
	formatTime,
	formatFloat,
	isEmptyGuid,
	anyInArray,
	padNumber,
	asyncForEach,
	createGuid,
	isGuid,
	delay,
	dataUrlToFile,
	getNextFocusableElement,
	parseFloatPrecise,
};
