import React, { useEffect, useState } from 'react';
import {
	IAnsweredOption,
	IPersonAnsweredOption,
	IQuestion,
	IRatingAnsweredOption,
	ISurvey,
} from '../../store/business/interfaces';
import { CommandButton } from '@fluentui/react';
import styles from './VotingForm.module.scss';
import { anyInArray } from '../../shared/utils';
import { useTranslation } from 'react-i18next';
import { ChoiceForm } from './ChoiceForm';
import { TextForm } from './TextForm';
import { PersonForm } from './PersonForm';
import { RatingForm } from './RatingForm';
import { HintTexts } from '../atoms/HintTexts';

export interface VotingFormProps {
	survey: ISurvey;
	question: IQuestion;
	onSend: (answeredOptions: IAnsweredOption[], comments?: string) => void;
	onSendBallotAnswers: (answeredOptions: (IRatingAnsweredOption  | IPersonAnsweredOption)[][]) => void;
	onUnVote: () => void;
	marginContainerName: string;
} 
/**
 *
 * @param props : {
	survey: ISurvey;
	question: IQuestion;
	onSend: (answeredOptions: IAnsweredOption[], comments?: string) => void;
	onSendPerson: (answeredOptions: IPersonAnsweredOption[][]) => void;
	onSendRating: (answeredOptions: IRatingAnsweredOption[]) => void;
	onUnVote: () => void;
	marginContainerName: string;
}
 * @returns RatingForm or other Form, depending on question.kind
 */
export const VotingForm = (props: VotingFormProps) => {
	const [comments, setComments] = useState<{ [id: string]: string }>({});
	const { t } = useTranslation();

	const upsertComments = (id: string, value: string) => {
		comments[id] = value;
		setComments(comments);
	};

	useEffect(() => {
		if (!comments[props.question.id]) {
			upsertComments(props.question.id, props.question.comment);
		}
	}, [props.question.id]);

	return (
		<div className={styles.votingFormContainer}>
			<div className={props.marginContainerName}>
				<div className={styles.votingFormChoice}>
					{props.question.votesPerMember > 0 ? (
						<>
							<HintTexts survey={props.survey} question={props.question} />
							{props.question.kind === 'choice' && (
								<>
									<ChoiceForm survey={props.survey} question={props.question} onSend={props.onSend} />
								</>
							)}
							{props.question.kind === 'text' && (
								<TextForm
									survey={props.survey}
									question={props.question}
									onSend={props.onSend}
								/>
							)}
							{props.question.kind === "person" && (
								<PersonForm
								survey={props.survey}
								question={props.question}
									onSend={props.onSendBallotAnswers}
								/>
							)}
							{
								props.question.kind === 'rating' && (
									<RatingForm 
										question={props.question}
										onSend={props.onSendBallotAnswers}
									/>
								)
							}
						</>
					) : (
						<> {t('form.questionCannotAnswered')}</>
					)}
				</div>
				{props.question.hasAnswered && props.question.security === 'public' ? (
					<>
						<CommandButton
							className={styles.button}
							text={t('form.changeVote')}
							disabled={!anyInArray(props.question.answeredOptions) && !props.question.comment}
							onClick={() => {
								props.onUnVote();
							}}
						/>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
