import React from "react";
import { ToggleableIcon } from "./ToggleableIcon";
import { useTranslation } from "react-i18next";
import { QuestionType } from "../../../store/business/interfaces/IQuestion";

export interface Props {
  question: {
    votesPerMember: number;
    kind: QuestionType;
  };
  numberOfOptions: number;
}

export const QuestionConfigurationIcons = (props: Props) => {
  const { t } = useTranslation();
  const [icons, setIcons] = React.useState([]);

  React.useEffect(() => {
    const result = [];

    if (props.question.kind === "person") {
      if (props.numberOfOptions > props.question.votesPerMember) {
        result.push({
          toggled: true,
          iconName: "MaximumValue",
          text: t("kind.limitedChoice"),
        });
      }
    }
    else {
      if (props.question.votesPerMember > 1) {
        result.push({
          toggled: true,
          iconName: "MultiSelect",
          text: t("kind.multipleChoice"),
        });
      } else {
        result.push({
          toggled: true,
          iconName: "CheckMark",
          text: t("kind.singleChoice"),
        });
      }
    }
    setIcons(result);
  }, [props.question, t]);

  return <ToggleableIcon icons={icons} />;
};
