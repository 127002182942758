import React from "react";
import { FontIcon, ImageIcon } from "@fluentui/react";
import styles from "./ToggleableIcon.module.scss";

export interface IToggleableIcon {
  toggled: boolean;
  iconName?: string;
  iconSrc?: string;
  text: string;
}

export interface Props {
  icons: IToggleableIcon[];
}

export const ToggleableIcon = (props: Props) => {
  let toggledIcon = props?.icons?.find((i) => i.toggled);

  return (
    <>
      {toggledIcon && (
        <span className={styles.toggleableIcon}>
          {toggledIcon.iconName && <FontIcon iconName={toggledIcon.iconName} />}
          {toggledIcon.iconSrc && (
            <ImageIcon
              imageProps={{ src: toggledIcon.iconSrc, width: 14, height: 20 }}
            />
          )}
          <span>{toggledIcon.text}</span>
        </span>
      )}
    </>
  );
};
