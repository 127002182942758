import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError, IErrorArea, ISystemState, LoadableObject } from './interfaces';
import { IMessage } from "../../shared/notifications";

const systemSlice = createSlice({
    name: "system",
    initialState: {
        errors: [],
        isLoading: [],
        messages: [],
    } as ISystemState,
    reducers: {
        addIsLoading: (state: ISystemState, action: PayloadAction<LoadableObject>) => {
            if(!state.isLoading.includes(action.payload)) {
                state.isLoading.push(action.payload);
            }
        },
        removeIsLoading: (state: ISystemState, action: PayloadAction<LoadableObject>) => {
            if(state.isLoading.includes(action.payload)){
                state.isLoading = state.isLoading.filter(l => l !== action.payload);
            }
        },
        addError: (state: ISystemState, action: PayloadAction<IError>) => {
            const errors = state.errors.filter(e => e.area !== action.payload.area);
            errors.push(action.payload);
            state.errors = errors;
        },
        removeError: (state: ISystemState, action: PayloadAction<IErrorArea>) => {
            const errors = state.errors.filter(e => e.area !== action.payload);
            state.errors = errors;
        },
        setApiBasePath: (state: ISystemState, action: PayloadAction<string>) => {
            state.apiBasePath = action.payload;
        },
        addMessage: (state: ISystemState, action: PayloadAction<IMessage>) => {
            state.messages.push({
                created : action.payload.created,
                type: action.payload.type,
                message: action.payload.message,
                sticky: action.payload.sticky
            }); 
        },
        removeMessage: (state: ISystemState, action: PayloadAction<number>) =>{
            state.messages = state.messages.filter((message, index) => !!message && index !== action.payload);
        },
        clearMessages: (state: ISystemState) => {
            state.messages = [];
        },
        setIsMenuPanelOpen: (state: ISystemState, action: PayloadAction<boolean>) => {
            state.isMenuPanelOpen = action.payload;
        },
        setConnectionId: (state: ISystemState, action: PayloadAction<string>) => {
            state.connectionId = action.payload;
        },
        setPieHeight: (state: ISystemState, action: PayloadAction<number>) => {
            state.pieHeight = action.payload;
        }
    },
})

export const selectSystem = (state: any) => state.system;
export const systemReducer = systemSlice.reducer;
export const {setPieHeight, addIsLoading, removeIsLoading, addMessage, removeMessage, clearMessages, addError, removeError, setApiBasePath, setIsMenuPanelOpen, setConnectionId} = systemSlice.actions;