import React from "react";
import { ToggleableIcon } from "./ToggleableIcon";
import { useTranslation } from "react-i18next";
import { QuestionType } from "../../../store/business/interfaces";

import ratingStarsUnchecked from "../../images/rating-icon-unchecked.svg";

export interface Props {
  question: { kind: QuestionType };
}

export const QuestionKindIcon = (props: Props) => {
  const { t } = useTranslation();
  return (
    <ToggleableIcon
      icons={[
        {
          toggled: props.question.kind === "choice",
          text: t("kind.choice"),
          iconName: "CheckList",
        },
        {
          toggled: props.question.kind === "person",
          text: t("kind.person"),
          iconName: "UserFollowed",
        },
        {
          toggled: props.question.kind === "text",
          text: t("kind.text"),
          iconName: "InsertTextBox",
        },
        {
          toggled: props.question.kind === "rating",
          text: t("kind.rating"),
          iconSrc: `${ratingStarsUnchecked}`,
        },
      ]}
    />
  );
};
