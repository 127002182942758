import React from 'react';
import { AnswerChoice, IPersonChoice } from '../../store/business/interfaces';
import { PersonChoiceIcon } from './PersonChoiceIcon';
import styles from '../organisms/PersonForm.module.scss';

export interface Props<T> {
	questionText: string;
	answeredOption: T | undefined;
	disabled: boolean;
	blockYes?: boolean;
	updateAnswers: (chosenAnswer: T) => void;
}

export const PersonAnswer = <T extends IPersonChoice>(props: Props<T>) => {
	return (
		<>
			<div className={styles.personAnswerTextDiv}>
				<span>{props.questionText}</span>
			</div>
			<PersonChoiceIcon
				answeredOption={props.answeredOption}
				updateAnswers={props.updateAnswers}
				answeredChoiceType={AnswerChoice.yes}
				disabled={props.disabled || props.blockYes}
			/>

			<PersonChoiceIcon
				answeredOption={props.answeredOption}
				updateAnswers={props.updateAnswers}
				answeredChoiceType={AnswerChoice.no}
				disabled={props.disabled}
			/>

			<PersonChoiceIcon
				answeredOption={props.answeredOption}
				updateAnswers={props.updateAnswers}
				answeredChoiceType={AnswerChoice.abstent}
				disabled={props.disabled}
			/>
		</>
	);
};
