import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonAnswer } from './PersonAnswer';
import { PersonBlockHeader } from './PersonBlockHeader';
import { IPersonChoice } from '../../store/business/interfaces';
import styles from '../organisms/PersonForm.module.scss';

interface Props<T> {
	answeredOption: T;
	disabled: boolean;
	updateAnswers: (chosenAnswer: T) => void;
}

export const PersonBlock = <T extends IPersonChoice>(props: Props<T>) => {
	const { t } = useTranslation();

	return (
		<>
			<PersonBlockHeader label={t('form.person.voteByList')} withLegend={true} />
			<div className={styles.personAnswerContainer}>
				<PersonAnswer
					answeredOption={props.answeredOption}
					disabled={props.disabled}
					questionText={t('form.person.voteByListDescription')}
					updateAnswers={props.updateAnswers}
				/>
			</div>
		</>
	);
};
