import { FontIcon } from '@fluentui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType, showMessage } from '../../shared/notifications';
import { IQuestion } from '../../store/business/interfaces';
import styles from './BallotCounter.module.scss';

export interface BallotCounterProps {
    question: IQuestion;
    setCurrent: Dispatch<SetStateAction<number>>;
    currentBallot: number;
    disabled: boolean;
    disabledMessage: string;
}

export const BallotCounter = (props: BallotCounterProps) => {

    const { t } = useTranslation();

    const totalBallots = props.question.userVoteWeight;

    return (
        <div>
            <div
                className={styles.ballotCounterContainer}
            >
                <FontIcon
                    iconName='ChevronLeftMed'
                    className={[`${styles.leftCounter}`, `${props.currentBallot === 0 ? styles.counterInvisible : ''}`].join(' ')}
                    onClick={() => props.setCurrent(Math.max(props.currentBallot - 1, 0))}
                    data-testid='leftCounter'
                />
                <span data-testid='ballotCounter'>
                    {t(`form.ballotCounter`, {
                        currentBallot: `${props.currentBallot + 1}`,
                        totalBallots: `${totalBallots}`,
                    })}
                </span>
                <div>
                    <FontIcon
                        iconName='ChevronRightMed'
                        className={[
                            styles.rightCounter,
                            (props.currentBallot === (totalBallots - 1) ? styles.counterInvisible : ''),
                            (props.disabled ? styles.disabled : '')
                        ].join(' ')}
                        onClick={() => {
                            if(props.disabled) {
                                showMessage(props.disabledMessage, MessageType.WARN)
                            }
                            else {
                                props.setCurrent(Math.min(props.currentBallot + 1, totalBallots - 1))
                            }
                        }}
                        data-testid='rightCounter'
                    />
                </div>
            </div>
        </div>
    )
}