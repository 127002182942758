import { useEffect, useRef } from 'react';

let documentElementName: string, eventName: string;
if (typeof document.hidden !== 'undefined') {
	// Opera 12.10 and Firefox 18 and later support
	documentElementName = 'hidden';
	eventName = 'visibilitychange';
} else if (typeof (document as any).msHidden !== 'undefined') {
	documentElementName = 'msHidden';
	eventName = 'msvisibilitychange';
} else if (typeof (document as any).webkitHidden !== 'undefined') {
	documentElementName = 'webkitHidden';
	eventName = 'webkitvisibilitychange';
}

export const useTabIsVisible = (cb: (visible: boolean) => void) => {
	const savedHandler = useRef<any>();
	useEffect(() => {
		savedHandler.current = () => {
			let visible = !(document as any)[documentElementName];
			cb(visible);
		};
	}, [cb]);
	useEffect(() => {
		const eventListener = (event: any) => savedHandler.current(event);
		document.addEventListener(eventName, eventListener, false);
		return () => {
			document.removeEventListener(eventName, eventListener);
		};
	});
};
