import React from 'react';
import { ToggleableIcon } from './ToggleableIcon';
import { useTranslation } from 'react-i18next';

export interface Props {
	question: { security: 'public' | 'private' };
}

export const SecurityIcon = (props: Props) => {
	const { t } = useTranslation();
	return (
		<ToggleableIcon
			icons={[
				{ toggled: props.question.security === 'private', text: t('security.private'), iconName: 'Lock' },
				{ toggled: props.question.security === 'public', text: t('security.public'), iconName: 'Unlock' }
			]}
		/>
	);
};
