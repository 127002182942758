import { store } from './store/store';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
// import { showError } from './shared/notifications';

axiosRetry(axios, { retries: 10, retryDelay: (retryCount) => 1000 });

export interface IResponse {
	data: any;
	contentType: string;
	headers: any;
}

export interface IRequestConfig extends AxiosRequestConfig {
	onError?: (response: AxiosError, status: Number) => Promise<IResponse>;
}

async function appXhr(url: string, opts?: IRequestConfig): Promise<IResponse> {
	return xhr(url, opts, false);
}

async function xhr(url: string, opts?: IRequestConfig, accessApi: boolean = true): Promise<IResponse> {
	const { system, session } = store.getState();
	opts = { method: 'GET', ...opts, withCredentials: true };
	opts.headers = {
		'content-type': 'application/json',
		'x-functions-key': session.appConfig?.functionsKey || '',
		'x-votr-user-id': session.user?.pin || '',
		'x-votr-survey-code': session.user?.surveyCode || '',
		'x-votr-connection-id': system.connectionId || '',
		'x-votr-tenant-id': session.tenantShortGuid || '',
		...opts.headers,
	} as Record<string, string>;
	//disable cache for ie
	if ((window.document as any).documentMode && opts.method!.toLocaleLowerCase() === 'get') {
		opts.params = { _r: Math.random().toString() };
	}
	try {
		const response = await axios(accessApi ? `${system.apiBasePath}/${url}` : `${url}`, opts);
		const contentType = (response.headers['content-type'] || '').toLowerCase();
		return { data: response.data, contentType, headers: response.headers };
	} catch (e) {
		const ex = e as AxiosError;
		if (opts.onError) {
			return opts.onError(ex, ex.response?.status || 400);
		} else {
			//showError(e);
			return Promise.reject(e);
		}
	}
}

export { xhr, appXhr };
