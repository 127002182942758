import { store } from '../store/store';
import { xhr, appXhr } from '../xhr';
import { showError } from '../shared/notifications';
import { QuestionService } from './QuestionService';
import { setAppConfig } from '../store/session/session-slice';
import { addIsLoading, removeIsLoading } from '../store/system/system-slice';

export class SystemService {
	public static async getMembersAppConfig(): Promise<any> {
//		const response = await appXhr(`https://midev200-votr-live-member-functionapp.azurewebsites.net/api/v1/Config`, {
		const response = await appXhr(`/api/v1/Config`, {
			method: 'GET',
			onError: (ex, status) => {
				showError(ex);
				return Promise.reject();
			},
		});
		return response.data;
	}

	public static async ensureConfig(): Promise<void> {
		const { system } = store.getState();
		if(system.isLoading.includes('config')) return;

		try {
			store.dispatch(addIsLoading('config'));
			const response = await xhr(`System/Config`, {
				method: 'GET',
				onError: (response, code) => {
					return QuestionService.onError(response, code);
				},
			});

			store.dispatch(
				setAppConfig({
					clientId: response.data['appClientId'],
					tenantId: response.data['tenantId'],
					functionsKey: response.data['apiKey'],
				})
			);
		} finally {
			store.dispatch(removeIsLoading('config'));
		}
	}
}
