import { store } from '../store/store';
import { questionLoaded, questionResultLoaded } from '../store/business/business-slice';
import { xhr } from '../xhr';
import {
	IQuestion,
	IAnswerOption,
	IQuestionResult,
	IPersonAnsweredOption,
	IRatingAnsweredOption,
	IAnsweredOption,
} from '../store/business/interfaces';
import { createGuid, anyInArray } from '../shared/utils';
import { VotingService } from './VotingService';
import { clearMessages, showError, showMessage, MessageType } from '../shared/notifications';
import { AxiosError } from 'axios';
import { IManager } from './manager/IManager';
import { DefaultManager } from './manager/DefaultManager';
import { addIsLoading, removeIsLoading } from '../store/system/system-slice';

export class QuestionService {
	private static managers: IManager[] = [];
	private static defaultManager: DefaultManager = new DefaultManager();

	/**
	 * adds a manager which maps answerOptions for different kind of questions
	 * @param manager IManager
	 */
	public static addManager(manager: IManager) {
		QuestionService.managers.push(manager);
	}

	/**
	 * clears state from redux store
	 */
	public static clearQuestion() {
		store.dispatch((dispatch) => {
			dispatch(questionLoaded(undefined));
			dispatch(questionResultLoaded(undefined));
		});
	}

	public static async onError(err: AxiosError<any>, status: Number) {
		showError(err);
		if (status === 400) {
			if (err?.response?.data === 'question.notStarted') {
				// question not in started state, reload all questions
				QuestionService.reloadAllQuestions(true);
			}
		} else if (status === 401) {
			console.debug('logout called because of onError in Question service 401');
			VotingService.logout().then(() => {
				window.location.href = '/?error=unauthorized';
			});
		} else if (status === 404) {
			console.debug('logout called because of onError in Question service 404');
			VotingService.logout().then(() => {
				window.location.href = `/?error=surveyNotFound`;
			});
		}
		return Promise.reject();
	}

	/**
	 * Sends the answers of a rating question to the server and updates the question the redux state.
	 * Shows a success message on success.
	 * @param merge : boolean
	 * @returns Promise<void>
	 */
	public static async reloadAllQuestions(merge: boolean = false) {
		const { business, system } = store.getState();

		if(system.isLoading.includes('question')) return;
		
		store.dispatch(addIsLoading('question'));		

		try {
			const reloadedQuestions = await this.getQuestions();
			if (reloadedQuestions) {
				if (reloadedQuestions[0].representation === 'question') {
					let reloadedRunningQuestions = reloadedQuestions.map((q) => q as IQuestion);
					if (merge) {
						// simply merge elements, don't look at properties (title, desc. etc.) yet
						let mergedQuestions = business.questions || [];

						mergedQuestions = mergedQuestions
							.filter((q) => reloadedRunningQuestions.some((rq) => rq.id === q.id))
							.concat(reloadedRunningQuestions.filter((rq) => !mergedQuestions.some((q) => q.id === rq.id)))
							.sort((q1, q2) => q1?.title.localeCompare(q2?.title));

						if (mergedQuestions.length === 0) mergedQuestions = undefined;

						store.dispatch(questionLoaded(mergedQuestions));
					} else {
						reloadedRunningQuestions = reloadedRunningQuestions?.sort((q1, q2) => q1?.title.localeCompare(q2?.title));
						store.dispatch(questionLoaded(reloadedRunningQuestions));
					}
					store.dispatch(questionResultLoaded(undefined));
				} else if (reloadedQuestions[0].representation === 'result') {
					store.dispatch((dispatch) => {
						dispatch(questionResultLoaded(reloadedQuestions[0] as IQuestionResult));
						dispatch(questionLoaded(undefined));
					});
				}
			} else {
				store.dispatch((dispatch) => {
					dispatch(questionResultLoaded(undefined));
					dispatch(questionLoaded(undefined));
				});
			}
		} finally {
			store.dispatch(removeIsLoading('question'));
		}
	}

	/**
	 * gets current state from redux store;
	 * maps answerOptions into new Object for Backend
	 * calls xhr-POST-request
	 * dispatches new state to redux store
	 * @param question currently active question
	 * @param answerOptions voted answers
	 * @param comment for question kind "text" only
	 * @param unansweredOptions necessary for redux store updates
	 * @returns
	 */
	public static async answerQuestion(
		question: IQuestion,
		answerOptions: IAnsweredOption[],
		comment: string,
		unansweredOptions?: IAnsweredOption[]
	) {
		const { business } = store.getState();
		// if (answerLoading) {
		// 	return;
		// }
		// store.dispatch(answerLoading (true));

		const responsibleManager =
			QuestionService.managers.find((m) => m.areYouResponsible(question)) || QuestionService.defaultManager;
		const answeredOptionsByQuestionKind = responsibleManager?.prepareAnsweredOptions(answerOptions);

		try {
			store.dispatch(addIsLoading('answer'));
			await xhr(`Voting/Answer`, {
				method: 'POST',
				data: {
					answerOptions: answeredOptionsByQuestionKind,
					votingId: question.votingId,
					questionId: question.id,
					comment,
				},
				onError: QuestionService.onError,
			});

			let questions = business.questions.map((q) => {
				if (q.id !== question.id) return q;
				const aos = unansweredOptions
					? unansweredOptions.concat(answeredOptionsByQuestionKind)
					: answeredOptionsByQuestionKind;
				return {
					...q,
					hasAnswered: anyInArray(answerOptions) || !!comment,
					answeredOptions: aos,
					comment,
				};
			});

			store.dispatch(questionLoaded(questions));
			showMessage('messages.answerSuccess', MessageType.SUCCESS, true);
		} finally {
			store.dispatch(removeIsLoading('answer'));
		}
	}

	public static async answerBallotQuestion(
		question: IQuestion,
		ballots: (IPersonAnsweredOption | IRatingAnsweredOption)[][]
	) {
		for (let i = 0; i < ballots.length; i++) {
			const filteredOptions = ballots.filter((b, index) => index !== i);
			let unansweredOptions: IAnsweredOption[] = [];
			filteredOptions.forEach((fo) => (unansweredOptions = unansweredOptions.concat(fo)));
			await QuestionService.answerQuestion(question, ballots[i], undefined, unansweredOptions);
		}
	}

	public static unAnswer(question: IQuestion) {
		const { business } = store.getState();
		let questions = business.questions.map((q) => {
			if (q.id !== question.id) return q;
			return { ...q, hasAnswered: false };
		});
		clearMessages();
		store.dispatch(questionLoaded(questions));
	}
	/**
	 * GET-Request to fetch unvoted or voted Question; calls QuestionService.parseList()
	 * @returns IQuestion | IQuestionResult
	 */
	private static async getQuestions(): Promise<(IQuestion | IQuestionResult)[] | undefined> {
		const response = await xhr(`Voting/Questions`, {
			method: 'GET',
			onError: QuestionService.onError,
		});
		const data = response.data;
		const questions = QuestionService.parseList(data ? data : null);
		return questions;
	}

	/**
	 * calls QuestionSerivce.parseElement and maps result from data into List
	 * @param data
	 * @returns IQuestion | IQuestionResult)[]
	 */
	private static parseList(data: Array<any>): (IQuestion | IQuestionResult)[] | undefined {
		if (!data) {
			return undefined;
		}
		return data.map((d) => this.parseElement(d));
	}

	/**
	 * parses response.data into one of the mentioned interfaces
	 * @param data : any
	 * @returns IQuestion | IQuestionResult | undefined
	 */
	private static parseElement(data: any): IQuestion | IQuestionResult | undefined {
		if (!data) {
			return undefined;
		}
		const representation = data['representation'];
		if (representation === 'question') {
			const answerOptions = (data['answerOptions'] as []) || [];
			const votesPerMember = parseInt(data['votesPerMember']);
			const userVoteWeight = parseFloat(data['userVoteWeight']);
			const answeredOptions = (data['answeredOptions'] as []) || [];
			return {
				representation: 'question',
				id: data['id'],
				title: data['title'],
				description: data['description'],
				answerOptions: answerOptions.map(
					(a: any) =>
						({
							id: a['id'],
							title: a['title'],
						} as IAnswerOption)
				),
				security: data['security'],
				kind: data['kind'],
				votesPerMember: isNaN(votesPerMember) ? 1 : votesPerMember,
				allowVotesSplitting: !!data['allowVotesSplitting'],
				votingId: createGuid(),
				hasAnswered: !!data['hasAnswered'],
				answeredOptions: answeredOptions.map((ao: any) => ({
					id: ao['answerOptionId'],
					voteWeight: parseFloat(ao['voteWeight']) ? parseFloat(ao['voteWeight']) : 1,
					answerChoice: ao['voteValue'],
					ballotNumber: ao['ballotNumber'],
					rating: ao['rating'],
				})),
				userVoteWeight: isNaN(userVoteWeight) ? 1 : userVoteWeight,
				comment: data['comment'],
				maxRating: data['maxRating'],
				block: data['block'],
				startTime: data['startTime'] ?? null,
				stopTime: data['stopTime'] ?? null,
				isTimerTriggered: data['isTimerTriggered'] ?? false,
			} as IQuestion;
		} else {
			const votesPerMember = parseInt(data['votesPerMember']);
			return {
				representation: 'result',
				description: data['description'],
				title: data['title'],
				security: data['security'],
				answerOptions: data['answerOptions'] || [],
				allowVotesSplitting: !!data['allowVotesSplitting'],
				votesPerMember: isNaN(votesPerMember) ? 1 : votesPerMember,
				comments: data['comments'] || [],
				kind: data['kind'],
				maxRating: data['maxRating'],
				startTime: data['startTime'] ?? null,
				stopTime: data['stopTime'] ?? null,
				isTimerTriggered: data['isTimerTriggered'] ?? false,
			} as IQuestionResult;
		}
	}
}
